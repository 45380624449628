import Typography from 'typography'
let theme = require('typography-theme-de-young').default

theme.headerFontFamily = ['IBM Plex Sans', 'sans-serif']
theme.bodyFontFamily = ['IBM Plex Sans', 'sans-serif']
theme.baseFontSize = '19px'
theme.baseLineHeight = 1.7
theme.scaleRatio = 5 / 2
theme.headerWeight = 700

theme.overrideThemeStyles = ({ rhythm }) => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    'blockquote > h1, blockquote > h2, blockquote > h3, blockquote > h4': {
      marginTop: 0,
    },
    'p': {
      marginBottom: rhythm(1),
    },
    'li > p': {
      marginBottom: rhythm(1 / 2),
    },
    'p code': {
      fontSize: '75%',
    },
    'tt,code': {
      fontSize: '85%',
    },
    pre: {
      lineHeight: 1.22,
    },
  }
}

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
