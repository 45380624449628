import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import Link from 'gatsby-link'


import { rhythm } from '../utils/typography'

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2.5),
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 57,
                borderRadius: `100%`,
              }}
            />
            <p>
              Delivered by <strong><Link style={{ boxShadow: 'none' }} to={'/about'}>Mengxi</Link></strong> who lives and works in San
              Francisco building <strike>useful</strike> things. You can also
              find me on{' '}
              <a href={`https://twitter.com/${social.twitter}`} target="_blank">
                Twitter
              </a>{' '}
              and{' '}
              <a
                href={`https://www.linkedin.com/in/${social.linkedin}`}
                target="_blank"
              >
                Linkedin
              </a>
              .
            </p>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 57, height: 57) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          linkedin
        }
      }
    }
  }
`

export default Bio
